<template>
  <sheet dense depressed light outlined>
    <v-data-table
      :headers="headers"
      :items="items"
      disable-sort
      disable-pagination
      hide-default-footer
    >
      <template #body="data">
        <slide-y-up-transition :delay="300" group tag="tbody">
          <receipt-item-row
            v-if="!readOnly && !data.items.length && canHaveEmptyItems"
            key="invoice-receipt-row-fixed"
            v-model="obPosition"
            can-add
            @add:empty="addEmptyItemPosition"
          />
          <template v-for="(item, index) in data.items">
            <receipt-item-row
              :key="`invoice-receipt-row-${uid(index)}`"
              :can-add="index === 0"
              :can-delete="data.items.length > 1"
              :edit="isReceiptCorrect"
              :value="item"
              @del="deleteItemPosition(index)"
              @update="updateItemPosition($event, index)"
              @add:empty="addEmptyItemPosition"
            />
          </template>
        </slide-y-up-transition>
      </template>
    </v-data-table>
  </sheet>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import { InvoicePosition } from "@planetadeleste/vue-mc-gw";

import { SlideYUpTransition } from "vue2-transitions";
import ReceiptItemRow from "@/modules/invoices/components/receipt/ReceiptItemRow.vue";
import { map, get, uniqueId } from "lodash";

@Component({
  components: { ReceiptItemRow, SlideYUpTransition },
})
export default class ReceiptItems extends Mixins(DataTableMixin, InvoiceMixin) {
  @Prop(Boolean) readonly readOnly!: boolean;

  headers: DataTableHeader[] = [
    { text: "cfecode", value: "cfe_code" },
    { text: "tax.percent", value: "tax_percent" },
    { text: "fee", value: "price" },
    { text: "value", value: "total_price_with_tax" },
  ];
  obPosition: InvoicePosition = new InvoicePosition();

  get items() {
    return map(this.positions, (obPosition) => {
      if (!obPosition.get("idx")) {
        obPosition.set("idx", uniqueId());
      }

      return obPosition;
    });
  }

  mounted() {
    this.addDTActionsHeader().mapDTHeaders();
    if (!this.exists) {
      this.resetPositions();
      this.addEmptyItemPosition();
    }
  }

  uid(iValue: number | string) {
    const obPosition = get(this.items, iValue);
    return obPosition && obPosition.get("idx")
      ? obPosition.get("idx")
      : uniqueId();
  }
}
</script>
